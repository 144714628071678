import React from "react";
import styles from "./Gallery.module.scss";
import useFetchData from "../../utils/FetchData";
import { useLanguage } from "../../context/Language";
import { getGallery } from "../../utils/api";

const Gallery = () => {
  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getGallery);
  return (
    <galleryWrapper>
      <textContent>
        <acc>
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
              stroke="#DD7D47"
              stroke-width="2"
            />
          </svg>
          {currentLanguage === "en" ? "Gallery" : "Galeria"}
        </acc>
        <h1>{currentLanguage === "en" ? "Gallery" : "Galeria"}</h1>
      </textContent>
      <gallery>
        {data?.data?.map((img, index) => {
          return <img src={img.image.url} key={index} alt="" />;
        })}
      </gallery>
    </galleryWrapper>
  );
};

export default Gallery;
