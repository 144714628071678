import React, { useEffect, useRef, useState } from "react";
import styles from "./About.module.scss";
import { useLanguage } from "../../context/Language";
import { getAboutPage } from "../../utils/api";
import useFetchData from "../../utils/FetchData";

const About = () => {
  const video = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    video.current.onplay = (e) => {
      setIsPlaying(true);
    };
    video.current.onpause = (e) => {
      setIsPlaying(false);
    };
  }, []);

  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getAboutPage, currentLanguage);
  return (
    <aboutWrapper>
      <hero>
        <textContent>
          <acc>
            <svg
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                stroke="#DD7D47"
                stroke-width="2"
              />
            </svg>
            {data?.data?.heroTagline}
          </acc>
          <h1>{data?.data?.heroText}</h1>
        </textContent>
        <blueBoxes>
          {data?.data?.blocks.map((data, index) => (
            <box key={index}>
              <h4>{data?.title}</h4>
              <p>{data?.content}</p>
            </box>
          ))}
        </blueBoxes>
      </hero>
      <section2>
        <titleContent>
          <acc>
            <svg
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                stroke="#31388F"
                stroke-width="2"
              />
            </svg>
            {data?.data?.valuesTagline}
          </acc>
          <h3>{data?.data?.valuesTitle}</h3>
        </titleContent>
        <cards>
          {data?.data?.value.map((data, index) => (
            <card key={index}>
              <img src={data?.icon?.url} alt="" />
              <h6>{data?.title}</h6>
              <p>{data?.content}</p>
            </card>
          ))}
        </cards>
      </section2>
      <section3>
        <acc>
          <svg
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 9C7.70914 9 9.5 7.20914 9.5 5C9.5 2.79086 7.70914 1 5.5 1C3.29086 1 1.5 2.79086 1.5 5C1.5 7.20914 3.29086 9 5.5 9Z"
              stroke="#DD7D47"
              stroke-width="2"
            />
          </svg>
          {data?.data?.videoTagline}
        </acc>
        <h3>{data?.data?.videoTitle}</h3>
        <videoWrapper
          onClick={() => {
            isPlaying ? video.current.pause() : video.current.play();
          }}
        >
          <video ref={video} src={data?.data?.video?.url} muted/>
          <svg
            style={{
              display: isPlaying ? "hidden" : "unset",
              animation: isPlaying
                ? `0.3s 1 forwards ${styles.playing}`
                : `0.5s 1 forwards ${styles.paused}`,
            }}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="#DD7D47" />
            <path
              d="M70 49.6376L40 65.942L40 33.3333L70 49.6376Z"
              fill="#F1F4F6"
            />
          </svg>
        </videoWrapper>
      </section3>
      <section4>
        <img src={data?.data?.aboutImage?.url} alt="" />
        <textContent>
          <h5>{data?.data?.aboutTitle}</h5>
          <p>{data?.data?.aboutContent}</p>
        </textContent>
      </section4>
      <section5>
        <textContent>
          <acc>
            <svg
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                stroke="#31388F"
                stroke-width="2"
              />
            </svg>
            {data?.data?.productTagline}
          </acc>
          <h3>{data?.data?.productTitle}</h3>
        </textContent>
        <products>
          {data?.data?.product.map((data, index) => (
            <product key={index}>
              <img src={data?.image?.url} alt="" />
              <h6>{data?.title}</h6>
              <p>{data?.content}</p>
            </product>
          ))}
        </products>
      </section5>
    </aboutWrapper>
  );
};

export default About;
