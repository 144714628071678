import React from "react";
import styles from "./BlogCard.module.scss";
import formatDateText from "../../utils/FormatDateText";
const BlogCard = (data) => {
  return (
    <a href={`/blogs/${data?.data?.slug}`} className={styles.blogCard}>
      <text>
        <date>{formatDateText(data?.data?.releaseDate)}</date>
        <h5>{data?.data?.title}</h5>
      </text>
      <img src={data?.data?.image?.url} alt="Blog Post" />
    </a>
  );
};

export default BlogCard;
