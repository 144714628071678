import { useState, useEffect } from 'react';


function useFetchData(fetchFunction, ...args) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const result = await fetchFunction(...args);
                setData(result);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [fetchFunction, ...args]);

    return { data, isLoading, error };
}
export default useFetchData;