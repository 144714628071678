import React from "react";
import "./Blogs.module.scss";
import { useLanguage } from "../../context/Language";
import BlogCard from "../../components/BlogCard/BlogCard";
import { getBlogs } from "../../utils/api";
import useFetchData from "../../utils/FetchData";
const Blogs = () => {
  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getBlogs, currentLanguage);
  return (
    <blogsWrapper>
      <blogsBigText>
        {currentLanguage === "en" ? "Read our news!" : "Lexoni lajmet tona!"}
      </blogsBigText>
      <blogGrid>
        {data?.data?.map((blog) => (
          <BlogCard key={blog.id} data={blog} />
        ))}
      </blogGrid>
    </blogsWrapper>
  );
};

export default Blogs;
