import React from "react";
import "./Furniture.module.scss";
import { useLanguage } from "../../context/Language";
import { getBrands } from "../../utils/api";
import useFetchData from "../../utils/FetchData";

const Furniture = () => {
  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getBrands);
  return (
    console.log(data),
    (
      <furnitureWrapper>
        <textContent>
          <acc>
            <svg
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                stroke="#DD7D47"
                stroke-width="2"
              />
            </svg>
            {currentLanguage === "en" ? "Brands" : "Brendet"}
          </acc>
          <h1>
            {currentLanguage === "en"
              ? "Suppliers & Brands"
              : "Furnitoret & brendet"}
          </h1>
        </textContent>
        <logosWrapper>
          {data?.data?.map((data, index) => (
            <img src={data.image.url} alt="" key={index} />
          ))}
        </logosWrapper>
      </furnitureWrapper>
    )
  );
};

export default Furniture;
