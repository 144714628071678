import React from "react";
import "./Terms.module.scss";
import { useLanguage } from "../../context/Language";

const Terms = () => {
  const { currentLanguage } = useLanguage();
  return (
    <terms>
      <strong>
        {currentLanguage === "en"
          ? "Terms and Conditions"
          : "Kushtet dhe Rregullat"}
      </strong>
      <br />
      <strong>
        {currentLanguage === "en"
          ? "Effective Date: 25/07/2024"
          : "Data e Fuqisë: 25/07/2024"}
      </strong>
      <br />
      <strong>
        {currentLanguage === "en" ? "1. Introduction" : "1. Hyrje"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? 'Welcome to Passable sh.p.k ("we", "our", "us"). By accessing or using our website passable-ks.com (the "Site"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use the Site.'
        : 'Mirë se vini në Passable sh.p.k ("ne", "tona", "ne"). Duke aksesuar ose përdorur faqen tonë të internetit passable-ks.com (e quajtur më tej "Faqja"), ju pranoni të përmbushni dhe të respektoni këto Kushtet dhe Rregullat. Nëse nuk jeni dakord me këto kushte, ju lutemi mos e përdorni Faqen.'}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "2. Use of the Site"
          : "2. Përdorimi i Faqes"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "You agree to use the Site only for lawful purposes and in accordance with these Terms and Conditions. You must not use the Site in any way that could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site."
        : "Ju pranoni të përdorni Faqen vetëm për qëllime ligjore dhe në përputhje me këto Kushtet dhe Rregullat. Ju nuk duhet të përdorni Faqen në ndonjë mënyrë që mund të dëmtojë, çaktivizojë, mbingarkojë ose pengojë Faqen, ose të ndërhyjë me përdorimin dhe kënaqësinë e Faqes nga palët e tjera."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "3. Intellectual Property"
          : "3. Pronësia Intelektuale"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "All content on the Site, including but not limited to text, graphics, logos, images, and software, is the property of Passable sh.p.k or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use any content from the Site without our express written permission."
        : "Të gjitha përmbajtjet në Faqe, përfshirë, por pa u kufizuar në tekste, grafika, logo, imazhe dhe softuer, janë pronë e Passable sh.p.k ose e licencuesve të tij dhe janë të mbrojtura nga ligjet për të drejtat e autorit, markat tregtare dhe të tjera të drejta intelektuale. Ju nuk mund të përdorni asnjë përmbajtje nga Faqja pa miratimin tonë të shprehur me shkrim."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "4. User Accounts"
          : "4. Llogaritë e Përdoruesve"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "If you create an account on the Site, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security."
        : "Nëse krijoni një llogari në Faqe, jeni përgjegjës për mbajtjen e konfidencialitetit të informacionit të llogarisë suaj dhe për të gjitha aktivitetet që ndodhin nën llogarinë tuaj. Ju pranoni të na informoni menjëherë për çdo përdorim të paautorizuar të llogarisë suaj ose për çdo shkelje tjetër të sigurisë."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "5. Booking and Payments"
          : "5. Rezervimet dhe Pagesat"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "Bookings made through the Site are subject to availability. Payments for reservations must be made in accordance with the payment methods specified on the Site. Any refunds or cancellations are subject to our Cancellation Policy."
        : "Rezervimet e bëra përmes Faqes janë të ndërlidhura me disponueshmërinë. Pagesat për rezervimet duhet të bëhen në përputhje me metodat e pagesës të specifikuara në Faqe. Çdo rimbursim ose anulim janë të nënshtruara ndaj Politikës sonë të Anulimit."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "6. Limitation of Liability"
          : "6. Kufizimi i Përgjegjësisë"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "Passable sh.p.k is not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of the Site. We do not guarantee that the Site will be error-free, secure, or uninterrupted."
        : "Passable sh.p.k nuk është përgjegjës për asnjë dëm të drejtpërdrejtë, të tërthortë, aksidental, pasojash ose ndëshkues që lindin nga ose janë të lidhura me përdorimin tuaj të Faqes. Ne nuk garantojmë që Faqja do të jetë pa gabime, e sigurtë ose e pandërprerë."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "7. Changes to the Terms"
          : "7. Ndryshimet në Kushtet"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "We reserve the right to update or modify these Terms and Conditions at any time. Any changes will be posted on this page, and your continued use of the Site constitutes acceptance of the updated Terms and Conditions."
        : "Ne ruajmë të drejtën për të përditësuar ose ndryshuar këto Kushtet dhe Rregulla në çdo kohë. Çdo ndryshim do të publikohet në këtë faqe, dhe përdorimi juaj i vazhdueshëm i Faqes do të përbëjë pranimin e Kushteve dhe Rregullave të përditësuara."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en" ? "8. Governing Law" : "8. Ligji në Fuqi"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "These Terms and Conditions are governed by and construed in accordance with the laws of Kosovo. Any disputes arising under or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in Kosovo."
        : "Këto Kushtet dhe Rregullat janë të rregulluara nga dhe interpretohen në përputhje me ligjet e Kosovës. Çdo mosmarrëveshje që lind nga ose në lidhje me këto Kushtet dhe Rregulla do të jetë nën juridiksionin ekskluziv të gjykatave në Kosovë."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en" ? "9. Contact Us" : "9. Na Kontaktoni"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "For any questions about these Terms and Conditions, please contact us at: Passable sh.p.k, Rrethi i Lagjes Marigona, Pristina, Kosovo, Prishtinë, Kosovë, 10000, info@passable-ks.com"
        : "Për çdo pyetje në lidhje me këto Kushtet dhe Rregulla, ju lutemi na kontaktoni në: Passable sh.p.k, Rrethi i Lagjes Marigona, Pristina, Kosovo, Prishtinë, Kosovë, 10000, info@passable-ks.com"}
    </terms>
  );
};

export default Terms;
