import React from "react";
import "./Contact.module.scss";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useLanguage } from "../../context/Language";
const ContactPage = () => {
  const { currentLanguage } = useLanguage();
  const initValues = {
    name: "",
    email: "",
    message: "",
  };

  const ValidationTemplate = yup.object({
    name: yup.string().required("Full name is required"),
    email: yup
      .string()
      .email("Incorrect email format")
      .required("Email is required"),
    message: yup.string().required("Message is required"),
  });

  async function handleButton(values, { setSubmitting, resetForm }) {
    try {
      const res = await axios.post(
        "https://passable-backend.onrender.com/api/contact",
        {
          data: values,
        }
      );
      if (res.status === 200) {
        resetForm();
      }
    } catch (error) {
      console.error("Error submitting the form", error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <contactWrapperPage>
      <iframe
        title="maps"
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC65Azad6Qh_IIqdiDjfz-NvJdEzZLfAiU&q=Passable+sh.p.k"
      />
      <Formik
        initialValues={initValues}
        validationSchema={ValidationTemplate}
        onSubmit={handleButton}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <h4>{currentLanguage === "en" ? "Contact Us" : "Na kontaktoni"}</h4>
            <p>
              {currentLanguage === "en"
                ? "Get in touch with our team to learn more about Passable"
                : "Kontaktoni me ekipin tonë për të mësuar më shumë rreth Passable"}
            </p>
            <acc>{currentLanguage === "en" ? "USERNAME" : "EMRI"}</acc>
            <Field
              type="text"
              id="name"
              name="name"
              placeholder={
                currentLanguage === "en" ? "Your name..." : "Emri juaj..."
              }
              style={{
                borderColor:
                  errors.name && touched.name ? "#ff0000" : "#131748",
              }}
            />
            {errors.name && touched.name && (
              <div className="error">{errors.name}</div>
            )}
            <acc>EMAIL</acc>
            <Field
              type="email"
              id="email"
              name="email"
              placeholder={
                currentLanguage === "en"
                  ? "Your email address..."
                  : "Email adresa juaj..."
              }
              style={{
                borderColor:
                  errors.email && touched.email ? "#ff0000" : "#131748",
              }}
            />
            {errors.email && touched.email && (
              <div className="error">{errors.email}</div>
            )}
            <acc>MESSAGE</acc>
            <Field
              type="text"
              name="message"
              id="message"
              placeholder={
                currentLanguage === "en" ? "Write here..." : "Shkruaj këtu..."
              }
              style={{
                borderColor:
                  errors.message && touched.message ? "#ff0000" : "#131748",
              }}
            />
            {errors.message && touched.message && (
              <div className="error">{errors.message}</div>
            )}
            <button
              type="submit"
              disabled={isSubmitting}
              style={{ cursor: isSubmitting ? "wait" : "pointer" }}
            >
              SEND MESSAGE
            </button>
          </Form>
        )}
      </Formik>
    </contactWrapperPage>
  );
};

export default ContactPage;
