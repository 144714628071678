import React, { useRef, useState } from "react";
import styles from "./Home.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import Contact from "../../components/Contact/Contact";
import { useLanguage } from "../../context/Language";
import useFetchData from "../../utils/FetchData";
import { getHomepage, getTestimonials } from "../../utils/api";

const Home = () => {
  const prevReviewRef = useRef(null);
  const nextReviewRef = useRef(null);

  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getHomepage, currentLanguage);
  const { data: testimonials } = useFetchData(getTestimonials, currentLanguage);
  const [activeTab, setActiveTab] = useState(0);
  return (
    <homeWrapper>
      <hero>
        <heroContent>
          <acc>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                stroke="#DD7D47"
                stroke-width="2"
              />
            </svg>
            Welcome
          </acc>
          <h1>{data?.data?.heroTitle}</h1>
          <button
            onClick={() => {
              window.open("/about", "_self");
            }}
          >
            {currentLanguage === "en" ? "Learn more" : "Më shumë"}
          </button>
        </heroContent>
      </hero>
      <section2>
        {data?.data?.objective?.map((data, index) => (
          <point key={index}>
            <left>
              <img src={data?.icon?.url} alt="" />
              <h4>{data?.name}</h4>
            </left>
            <p>{data?.description}</p>
          </point>
        ))}
      </section2>
      {/* <section3>
        <img src={data?.data?.bigImage?.url} alt="" />
        <reviewsWrapper>
          <h3>
            {currentLanguage === "en" ? "Our Clients Say" : "Klientat tonë"}
          </h3>
          <theReviews>
            <svg
              width="60"
              height="45"
              viewBox="0 0 60 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 0L0 22.5V45H22.5V0ZM60 0L37.5 22.5V45H60V0Z"
                fill="#DD7D47"
              />
            </svg>

            <Swiper
              navigation={{
                prevEl: prevReviewRef.current,
                nextEl: nextReviewRef.current,
              }}
              onSwiper={(swiper) => {
                //thank you chatgpt
                // Assign navigation elements once the swiper instance is ready
                swiper.params.navigation.prevEl = prevReviewRef.current;
                swiper.params.navigation.nextEl = nextReviewRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              modules={[Navigation]}
              className={styles.swiperWrapper}
            >
              {testimonials?.data?.map((data, index) => (
                <SwiperSlide className={styles.review} key={index}>
                  <reviewText>{data?.review}</reviewText>
                  <reviewerInfo>
                    <img src={data?.image.url} alt="" />
                    <textContent>
                      <name>{data?.name}</name>
                      <job>{data?.position}</job>
                    </textContent>
                  </reviewerInfo>
                </SwiperSlide>
              ))}
              <buttonWrapper>
                <svg //MAKE HOVER STATE LATER
                  className={styles.prev}
                  ref={prevReviewRef}
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="#F1F4F6"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 21C1 9.9543 9.95431 1 21 1C32.0457 1 41 9.9543 41 21C41 32.0457 32.0457 41 21 41C9.95431 41 1 32.0457 1 21Z"
                    stroke="#BACCD6"
                    stroke-width="2"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.7558 14.579C21.0812 14.9044 21.0812 15.4321 20.7558 15.7575L16.3451 20.1683H27.6666C28.1269 20.1683 28.5 20.5414 28.5 21.0016C28.5 21.4619 28.1269 21.8349 27.6666 21.8349H16.3451L20.7556 26.2457C21.0811 26.5711 21.0811 27.0988 20.7556 27.4242C20.4302 27.7496 19.9026 27.7496 19.5771 27.4242L13.7441 21.5909C13.4186 21.2654 13.4186 20.7378 13.7441 20.4124L19.5772 14.579C19.9027 14.2536 20.4303 14.2536 20.7558 14.579Z"
                    fill="#131748"
                  />
                </svg>
                <svg
                  className={styles.next}
                  ref={nextReviewRef}
                  width="42"
                  height="42"
                  viewBox="0 0 42 42"
                  fill="#F1F4F6"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 21C1 9.9543 9.95431 1 21 1C32.0457 1 41 9.9543 41 21C41 32.0457 32.0457 41 21 41C9.95431 41 1 32.0457 1 21Z"
                    stroke="#BACCD6"
                    stroke-width="2"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.7558 14.579C21.0812 14.9044 21.0812 15.4321 20.7558 15.7575L16.3451 20.1683H27.6666C28.1269 20.1683 28.5 20.5414 28.5 21.0016C28.5 21.4619 28.1269 21.8349 27.6666 21.8349H16.3451L20.7556 26.2457C21.0811 26.5711 21.0811 27.0988 20.7556 27.4242C20.4302 27.7496 19.9026 27.7496 19.5771 27.4242L13.7441 21.5909C13.4186 21.2654 13.4186 20.7378 13.7441 20.4124L19.5772 14.579C19.9027 14.2536 20.4303 14.2536 20.7558 14.579Z"
                    fill="#131748"
                  />
                </svg>
              </buttonWrapper>
            </Swiper>
          </theReviews>
        </reviewsWrapper>
      </section3> */}
      <section4>
        <left>
          {data?.data?.bulletPoints?.map((item, index) => (
            <h5
              key={index} 
              onClick={() => setActiveTab(index)}
              style={{
                cursor: "pointer",
                opacity: activeTab === index ? 1 : 0.3,
              }}
            >
              <square />
              {item?.nav}
            </h5>
          ))}
        </left>
        <right>
          {data?.data?.bulletPoints?.map((item, index) => (
            <tab
              key={index}
              style={{ display: activeTab === index ? "flex" : "none" }}
            >
              <textContent>
                <acc>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                      stroke="#DD7D47"
                      stroke-width="2"
                    />
                  </svg>
                  Welcome
                </acc>
                <h4>{item?.title}</h4>
              </textContent>
              <img src={item?.image[0]?.url} alt="" />
            </tab>
          ))}
        </right>
      </section4>
      <section5>
        <top>
          <p>{data?.data?.infoTagLine}</p>
          <right>
            <h5>{data?.data?.infoTitle}</h5>
          </right>
        </top>
        <bottom>
          <example>
            <img src={data?.data?.infocardImage1?.url} alt="" />
            <textContent>
              <div>
                <h5> {data?.data?.infocardTitle1}</h5>

                <p>{data?.data?.infocardText1}</p>
              </div>
              <button
                onClick={() => {
                  window.open("/suppliers", "_self");
                }}
              >
                {currentLanguage === "en" ? "learn more" : "Mëso më shumë"}
              </button>
            </textContent>
          </example>
          <example>
            <img alt="" src={data?.data?.infocardImage2?.url} />
            <textContent>
              <div>
                <h5> {data?.data?.inforcardTitle2}</h5>

                <p>{data?.data?.infocardText2}</p>
              </div>
              <button
                onClick={() => {
                  window.open("/gallery", "_self");
                }}
              >
                {currentLanguage === "en" ? "learn more" : "Mëso më shumë"}
              </button>
            </textContent>
          </example>
        </bottom>
      </section5>
      <Contact />
    </homeWrapper>
  );
};

export default Home;
