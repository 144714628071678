import React from "react";
import "./Privacy.module.scss";
import { useLanguage } from "../../context/Language";

const Privacy = () => {
  const { currentLanguage } = useLanguage();
  return (
    <privacy>
      <strong>
        {currentLanguage === "en" ? "Privacy Policy" : "Politika e Privatësisë"}
      </strong>
      <br />
      <strong>
        {currentLanguage === "en"
          ? "Effective Date: 25/07/2024"
          : "Data: 25/07/2024"}
      </strong>
      <br />
      <strong>{currentLanguage === "en" ? "Introduction" : "Hyrje"}</strong>
      <br />
      {currentLanguage === "en"
        ? 'Welcome to Passable sh.p.k ("we," "our," or "us"). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website passable-ks.com. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.'
        : 'Mirë se vini në Passable sh.p.k ("ne," "jonë," ose "ne"). Ne jemi të përkushtuar për të mbrojtur dhe respektuar privatësinë tuaj. Kjo Politikë e Privatësisë shpjegon se si ne mbledhim, përdorim, zbulojmë dhe mbrojmë informacionin tuaj kur vizitoni faqen tonë të internetit passable-ks.com. Ju lutemi lexoni me kujdes këtë politikë për të kuptuar qëndrimet dhe praktikat tona në lidhje me të dhënat tuaja personale dhe si do t\'i trajtojmë ato.'}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "Information We Collect"
          : "Informacioni që mbledhim"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "Our website is static and primarily informative. We collect only the necessary information through cookies to improve your browsing experience:"
        : "Faqja jonë e internetit është statike dhe kryesisht informative. Ne mbledhim vetëm informacionin e nevojshëm përmes cookies për të përmirësuar përvojën tuaj të shfletimit:"}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? " 1. Necessary Cookies:"
          : "1. Cookies të nevojshme:"}
      </strong>
      {currentLanguage === "en"
        ? "These cookies are essential for the website to function correctly. They ensure basic functionalities and security features of the website."
        : "Këto cookies janë thelbësore për funksionimin e duhur të faqes së internetit. Ato sigurojnë funktionalitet bazë dhe veçoritë e sigurisë së faqes së internetit."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "How We Use Your Information"
          : "Si e përdorim informacionin tuaj"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "We use the collected information in the following ways:"
        : "Ne përdorim informacionin e mbledhur në mënyrat e mëposhtme:"}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "1. To Improve Our Website:"
          : "1. Për të përmirësuar faqen tonë të internetit:"}
      </strong>
      {currentLanguage === "en"
        ? "To enhance your browsing experience and ensure our website functions correctly."
        : "Për të përmirësuar përvojën tuaj të shfletimit dhe për të siguruar funksionimin e duhur të faqes sonë të internetit."}

      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "Disclosure of Your Information"
          : "Zbulimi i informacionit tuaj"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "We do not share your personal information with third parties except in the following circumstances:"
        : "Ne nuk ndajmë informacionin tuaj personal me palë të treta përveç në rrethanat e mëposhtme:"}
      <br />
      <br />
      <strong>
        {" "}
        {currentLanguage === "en"
          ? "1. Legal Requirements:"
          : "1. Kërkesat ligjore:"}
      </strong>
      {currentLanguage === "en"
        ? " If required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency)."
        : " Nëse kërkohet të bëhet kështu me ligj ose në përgjigje të kërkesave të vlefshme nga autoritetet publike (p.sh., një gjykatë ose një agjensi qeveritare)."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en" ? "Your Rights" : "Të Drejtat tuaja"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "You have the right to access, update, or delete the information we have on you. If you wish to exercise any of these rights, please contact us at info@passable-ks.com."
        : "Ju keni të drejtë të qasje, përditësoni ose fshini informacionin që kemi për ju. Nëse dëshironi të ushtroni ndonjë nga këto të drejta, ju lutemi na kontaktoni në info@passable-ks.com."}
      <br />
      <br />
      <strong>
        {currentLanguage === "en" ? "Contact Us" : "Na kontaktoni"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "If you have any questions about this Privacy Policy, please contact us: Passable sh.p.k Rrethi i Lagjes Marigona, Pristina, Kosovo Prishtinë, Kosovë, 10000 info@passable-ks.com"
        : "Nëse keni ndonjë pyetje në lidhje me këtë Politikë të Privatësisë, ju lutemi na kontaktoni: Passable sh.p.k Rrethi i Lagjes Marigona, Pristina, Kosovo Prishtinë, Kosovë, 10000 info@passable-ks.com"}
      <br />
      <br />
      <strong>
        {currentLanguage === "en"
          ? "Changes to This Privacy Policy"
          : "Ndryshimet në Këtë Politikë të Privatësisë"}
      </strong>
      <br />
      {currentLanguage === "en"
        ? "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
        : "Ne mund të përditësojmë Politikën tonë të Privatësisë herë pas here. Ne do t'ju njoftojmë për çdo ndryshim duke postuar Politikën e re të Privatësisë në këtë faqe. Ju këshillojmë të rishikoni periodikisht këtë Politikë të Privatësisë për ndonjë ndryshim. Ndryshimet në këtë Politikë të Privatësisë janë efektive kur ato publikohen në këtë faqe."}
    </privacy>
  );
};

export default Privacy;
