import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import styles from "./Jobs.module.scss";
import { getJobs } from "../../utils/api";
import { useLanguage } from "../../context/Language";
import useFetchData from "../../utils/FetchData";

const Jobs = () => {
  const { currentLanguage } = useLanguage();
  const { data } = useFetchData(getJobs, currentLanguage);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // Add a state to track submission
  const formRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      address: "",
      city: "",
      position: "",
      file1: null,
      file2: null,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phoneNumber: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      position: Yup.string().required("Required"),
      file1: Yup.mixed()
        .required("Resume is required")
        .test("fileType", "Only PDF files are accepted", (value) =>
          value ? value.type === "application/pdf" : true
        ),
      file2: Yup.mixed().test(
        "fileType",
        "Only PDF files are accepted",
        (value) => (value ? value.type === "application/pdf" : true)
      ),
    }),
    onSubmit: async (values) => {
      const formDataToSend = new FormData();
      formDataToSend.append("fullName", values.fullName);
      formDataToSend.append("email", values.email);
      formDataToSend.append("phoneNumber", values.phoneNumber);
      formDataToSend.append("address", values.address);
      formDataToSend.append("city", values.city);
      formDataToSend.append("position", values.position);
      formDataToSend.append("file1", values.file1);
      formDataToSend.append("file2", values.file2);

      try {
        const response = await axios.post(
          "https://passable-backend.onrender.com/api/job-application",
          formDataToSend
        );
        console.log("Application sent!", response.data);
        setIsSubmitted(true); // Set the state to true upon successful submission
      } catch (error) {
        console.error("Form submission blocked due to errors", error);
      }
    },
  });

  const handleApplyClick = (title) => {
    setSelectedPosition(title);
    formik.setFieldValue("position", title);
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.jobsWrapper}>
      <div className={styles.hero}>
        <div className={styles.acc}>
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
              stroke="#DD7D47"
              strokeWidth="2"
            />
          </svg>
          {currentLanguage === "en" ? "Human resources" : "Burimet njerezore"}
        </div>
        <h1>
          {currentLanguage === "en" ? "Current Positions" : "vendet aktuale"}
        </h1>
      </div>
      <div className={styles.section2}>
        {data?.data?.map((job, index) => (
          <Job
            key={index}
            status={job.status}
            title={job.title}
            description={job.description}
            onApplyClick={handleApplyClick}
          />
        ))}
      </div>

      {isSubmitted ? ( // Check if the form has been submitted
        <div className={styles.thankYou}>
          <text>
            <h4>
              {currentLanguage === "en"
                ? "Thank you for applying to become part sh.p.k!"
                : "Faleminderit që aplikuat për tu bërë pjesë e Passable sh.p.k!"}
            </h4>
            <p>
              {currentLanguage === "en"
                ? "Human Resources staff will contact you shortly."
                : "Stafi i Burimeve njerëzore do të ju kontaktojë shumë shpejtë."}
            </p>
          </text>
          <a href="/">Go to home</a>
        </div>
      ) : (
        <div className={styles.applicationWrapper} ref={formRef}>
          <div className={styles.titleContent}>
            <div className={styles.acc}>
              <svg
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
                  stroke="#DD7D47"
                  strokeWidth="2"
                />
              </svg>
              {currentLanguage === "en"
                ? "employment application"
                : "aplikacioni per punesim"}
            </div>
            <h3>
              {currentLanguage === "en"
                ? "Want to become part of Passable?"
                : "Dëshironi të bëheni pjesë e Passable?"}
            </h3>
            <p>
              {currentLanguage === "en"
                ? "To apply for a job at Passable, please complete the application following. Thank you for your interest?"
                : "  Per te aplikuar per pune ne Passable, ju lutem plotesoni aplikacionin ne vijim. Ju faleminderit per interesimin tuaj?"}
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.entriesSection}>
              <h5>
                {currentLanguage === "en"
                  ? "Basic information"
                  : "Informacionet bazë"}
              </h5>
              <input
                placeholder={
                  currentLanguage === "en" ? "Full name" : "Emri i plotë"
                }
                name="fullName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullName}
                className={
                  formik.touched.fullName && formik.errors.fullName
                    ? `${styles.errorInput}`
                    : ""
                }
              />

              <input
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={
                  formik.touched.email && formik.errors.email
                    ? `${styles.errorInput}`
                    : ""
                }
              />

              <input
                placeholder={
                  currentLanguage === "en"
                    ? "Phone number"
                    : "Numri i telefonit"
                }
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                className={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? `${styles.errorInput}`
                    : ""
                }
              />

              <input
                placeholder={currentLanguage === "en" ? "Address" : "Adresa"}
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                className={
                  formik.touched.address && formik.errors.address
                    ? `${styles.errorInput}`
                    : ""
                }
              />

              <input
                placeholder={currentLanguage === "en" ? "City" : "Qyteti"}
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                className={
                  formik.touched.city && formik.errors.city
                    ? `${styles.errorInput}`
                    : ""
                }
              />
            </div>
            <div className={styles.entriesSection}>
              <h5>Pozita per te cilen po aplikoni</h5>
              <input
                placeholder={
                  currentLanguage === "en" ? "Write here" : "Shkruaj ketu"
                }
                name="position"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.position}
                className={
                  formik.touched.position && formik.errors.position
                    ? `${styles.errorInput}`
                    : ""
                }
              />
            </div>

            <div className={styles.entriesSection}>
              <h5>Dokumentet</h5>
              <input
                type="file"
                id="file1"
                name="file1"
                style={{ display: "none" }}
                onChange={(event) =>
                  formik.setFieldValue("file1", event.currentTarget.files[0])
                }
                onBlur={formik.handleBlur}
                required
              />
              <label
                htmlFor="file1"
                className={`${styles.customFileInput} ${
                  formik.touched.resume && formik.errors.resume
                    ? `${styles.errorInput}`
                    : ""
                }`}
              >
                <p>
                  {formik.values.resume
                    ? formik.values.resume.name
                    : "Upload CV"}
                </p>
                <span className={styles.fileInputLabel}>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                  >
                    <path
                      d="M4.58333 16.8334C3.30556 16.8334 2.22222 16.389 1.33333 15.5001C0.444444 14.6112 0 13.5279 0 12.2501V3.50008C0 2.58341 0.326389 1.79869 0.979167 1.14591C1.63194 0.493137 2.41667 0.166748 3.33333 0.166748C4.25 0.166748 5.03472 0.493137 5.6875 1.14591C6.34028 1.79869 6.66667 2.58341 6.66667 3.50008V11.4167C6.66667 12.0001 6.46528 12.4931 6.0625 12.8959C5.65972 13.2987 5.16667 13.5001 4.58333 13.5001C4 13.5001 3.50694 13.2987 3.10417 12.8959C2.70139 12.4931 2.5 12.0001 2.5 11.4167V3.50008H3.75V11.4167C3.75 11.6529 3.82986 11.8508 3.98958 12.0105C4.14931 12.1702 4.34722 12.2501 4.58333 12.2501C4.81944 12.2501 5.01736 12.1702 5.17708 12.0105C5.33681 11.8508 5.41667 11.6529 5.41667 11.4167V3.50008C5.41667 2.91675 5.21528 2.42369 4.8125 2.02091C4.40972 1.61814 3.91667 1.41675 3.33333 1.41675C2.75 1.41675 2.25694 1.61814 1.85417 2.02091C1.45139 2.42369 1.25 2.91675 1.25 3.50008V12.2501C1.25 13.1667 1.57639 13.9515 2.22917 14.6042C2.88194 15.257 3.66667 15.5834 4.58333 15.5834C5.5 15.5834 6.28472 15.257 6.9375 14.6042C7.59028 13.9515 7.91667 13.1667 7.91667 12.2501V3.50008H9.16667V12.2501C9.16667 13.5279 8.72222 14.6112 7.83333 15.5001C6.94445 16.389 5.86111 16.8334 4.58333 16.8334Z"
                      fill="#131748"
                    />
                  </svg>
                </span>
              </label>

              <input
                type="file"
                id="file2"
                name="file2"
                style={{ display: "none" }}
                onChange={(event) =>
                  formik.setFieldValue("file2", event.currentTarget.files[0])
                }
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor="file2"
                className={`${styles.customFileInput} ${
                  formik.touched.application && formik.errors.application
                    ? `${styles.errorInput}`
                    : ""
                }`}
              >
                <p>
                  {formik.values.application
                    ? formik.values.application.name
                    : "Upload Cover Letter"}
                </p>
                <span className={styles.fileInputLabel}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="17"
                    viewBox="0 0 10 17"
                    fill="none"
                  >
                    <path
                      d="M4.58333 16.8334C3.30556 16.8334 2.22222 16.389 1.33333 15.5001C0.444444 14.6112 0 13.5279 0 12.2501V3.50008C0 2.58341 0.326389 1.79869 0.979167 1.14591C1.63194 0.493137 2.41667 0.166748 3.33333 0.166748C4.25 0.166748 5.03472 0.493137 5.6875 1.14591C6.34028 1.79869 6.66667 2.58341 6.66667 3.50008V11.4167C6.66667 12.0001 6.46528 12.4931 6.0625 12.8959C5.65972 13.2987 5.16667 13.5001 4.58333 13.5001C4 13.5001 3.50694 13.2987 3.10417 12.8959C2.70139 12.4931 2.5 12.0001 2.5 11.4167V3.50008H3.75V11.4167C3.75 11.6529 3.82986 11.8508 3.98958 12.0105C4.14931 12.1702 4.34722 12.2501 4.58333 12.2501C4.81944 12.2501 5.01736 12.1702 5.17708 12.0105C5.33681 11.8508 5.41667 11.6529 5.41667 11.4167V3.50008C5.41667 2.91675 5.21528 2.42369 4.8125 2.02091C4.40972 1.61814 3.91667 1.41675 3.33333 1.41675C2.75 1.41675 2.25694 1.61814 1.85417 2.02091C1.45139 2.42369 1.25 2.91675 1.25 3.50008V12.2501C1.25 13.1667 1.57639 13.9515 2.22917 14.6042C2.88194 15.257 3.66667 15.5834 4.58333 15.5834C5.5 15.5834 6.28472 15.257 6.9375 14.6042C7.59028 13.9515 7.91667 13.1667 7.91667 12.2501V3.50008H9.16667V12.2501C9.16667 13.5279 8.72222 14.6112 7.83333 15.5001C6.94445 16.389 5.86111 16.8334 4.58333 16.8334Z"
                      fill="#131748"
                    />
                  </svg>
                </span>
              </label>
            </div>
            <button type="submit">Send Application</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Jobs;

const Job = ({ title, description, status, onApplyClick }) => {
  const { currentLanguage } = useLanguage();
  return (
    <div className={styles.jobWrapper}>
      <div className={styles.left}>
        <div className={styles.acc}>
          <svg
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 9C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1C2.79086 1 1 2.79086 1 5C1 7.20914 2.79086 9 5 9Z"
              stroke="#DD7D47"
              strokeWidth="2"
            />
          </svg>
          {status}
        </div>
        <h4>{title}</h4>
      </div>
      <div className={styles.right}>
        <p>{description}</p>
        <button onClick={() => onApplyClick(title)}>
          {currentLanguage === "en" ? "Apply now" : "Apliko"}
        </button>
      </div>
    </div>
  );
};
