import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { LanguageProvider } from "./context/Language";

const root = ReactDOM.createRoot(document.getElementById("root"));
//when react sees all the custom tag names,
//it assumes that we tried to make a react
//component and outputs an error (which is
//completely harmless). This checks
//any error logged and if its the kind of error
//described earlier, it wont output it.
const realError = console.error;
console.error = (...x) => {
  // debugger;
  if (
    x[0] ===
    "Warning: The tag <%s> is unrecognized in this browser. If you meant to render a React component, start its name with an uppercase letter.%s" ||
    "Warning: The tag <%s/> is unrecognized in this browser. If you meant to render a React component, start its name with an uppercase letter.%s"
  ) {
    return;
  }
  realError(...x);
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </BrowserRouter>
  </React.StrictMode>
);
