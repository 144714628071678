import React, { useEffect, useState } from "react";
import styles from "./SingleBlog.module.scss";
import ig from "../../assets/Instagram.svg";
import fb from "../../assets/Facebook.svg";
import pi from "../../assets/Pinterest.svg";
import tw from "../../assets/Twitter.svg";
import BlockRendererClient from "../../components/BlockRenderer/BlockRenderer";
import { useParams } from "react-router-dom";
import { getBlog } from "../../utils/api";
import formatDateText from "../../utils/FormatDateText";
const SingleBlog = () => {
  const [blog, setBlog] = useState({});
  const { slug } = useParams();

  const fetchBlog = async () => {
    const res = await getBlog(slug);
    setBlog(res.data[0]);
  };

  useEffect(() => {
    fetchBlog();
  }, []);
  const shareUrl = window.location.href;
  return (
    <div className={styles.singleBlogWrapper}>
      <div className={styles.top}>
        <div className={styles.mainHeader}>
          <div className={styles.blogInfo}>
            <div className={styles.contentInfo}>
              <div className={styles.date}>
                <p>{formatDateText(blog?.releaseDate)}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle cx="4" cy="4" r="4" fill="#31388F" />
                </svg>
                <p>{blog?.Category}</p>
              </div>
              <h3>{blog?.title}</h3>
            </div>
            <p>{blog?.description}</p>
          </div>
          <div className={styles.authorInfo}>
            <div className={styles.author}>
              <img
                src={blog?.authorImage?.url}
                alt=""
                className={styles.authorImage}
              />
              <div className={styles.authorDetails}>
                <div className={styles.name}>{blog?.author}</div>
                <div className={styles.position}>{blog?.authorPosition}</div>
              </div>
            </div>
            <p>{blog?.minRead} min read</p>
          </div>
        </div>
        <img src={blog?.image?.url} alt="" className={styles.headerImage} />
      </div>
      <div className={styles.contentDiv}>
        <div className={styles.share}>
          <div className={styles.tagLine}>SHARE</div>
          <div className={styles.shareLinks}>
            <a
              href={`https://www.instagram.com/?url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ig} alt="Share on Instagram" />
            </a>

            <a
              href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${blog?.title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tw} alt="Share on Twitter" />
            </a>
            <a
              href={`https://pinterest.com/pin/create/button/?url=${shareUrl}&media=${blog?.image?.url}&description=${blog?.title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={pi} alt="Share on Pinterest" />
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={fb} alt="Share on Facebook" />
            </a>
          </div>
        </div>
        <div className={styles.content}>
          <BlockRendererClient content={blog?.content} />
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
