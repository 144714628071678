import { Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Furniture from "./Pages/Furniture/Furniture";
import Gallery from "./Pages/Gallery/Gallery";
import Jobs from "./Pages/Jobs/Jobs";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ContactPage from "./Pages/Contact/ContactPage";
import Privacy from "./Pages/PrivacyPolicy/Privacy";
import Terms from "./Pages/Terms/Terms";
import Blogs from "./Pages/Blogs/Blogs";
import SingleBlog from "./Pages/SingleBlog/SingleBlog";

function App() {

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/suppliers" element={<Furniture />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<SingleBlog />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
