import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://passable-backend.onrender.com/api',
});

const checkLang = (lang) => {
    if (!lang) {
        throw new Error('Language parameter is required.');
    }
};

export const getHomepage = async (lang) => {
    checkLang(lang);
    const response = await axiosInstance.get(`/homepage?locale=${lang}&populate=*,objective.icon,bigImage,heroImage,bulletPoints.image,infocardImage1,infocardImage2`);
    return response?.data ?? null;
};

export const getTestimonials = async (lang) => {
    checkLang(lang);
    const response = await axiosInstance.get(`/testimonials?locale=${lang}&populate=*`);
    return response?.data ?? null;
};

export const getJobs = async (lang) => {
    checkLang(lang);
    const response = await axiosInstance.get(`/job-positions?locale=${lang}&populate=*`);
    return response?.data ?? null;
};

export const getBrands = async () => {
    const response = await axiosInstance.get(`/brands?populate=*&pagination[limit]=100`);
    return response?.data ?? null;
};

export const getGallery = async () => {
    const response = await axiosInstance.get(`/galleries?populate=*&pagination[limit]=100`);
    return response?.data ?? null;
};

export const getBrandspage = async (lang) => {
    checkLang(lang);
    const response = await axiosInstance.get(`/homepage?locale=${lang}&populate=*`);
    return response?.data ?? null;
};

export const getAboutPage = async (lang) => {
    checkLang(lang);
    const response = await axiosInstance.get(`/aboutpage?locale=${lang}&populate=*,blocks,value.icon,video,aboutImage,product.image`);
    return response?.data ?? null;
};

export const getBlogs = async (lang) => {
    checkLang(lang);
    const response = await axiosInstance.get(`/blogs?locale=${lang}&populate=*`);
    return response?.data ?? null;
};
export const getBlog = async (slug) => {
    const response = await axiosInstance.get(`/blogs?populate=*&filters[slug][$eq]=${slug}`);
    return response?.data ?? null;
};

